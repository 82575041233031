import React from "react";
import {
  Slider,
  Card,
  CardContent,
  CssBaseline,
  InputAdornment,
  FormControl,
  InputLabel,
  FilledInput,
  Zoom,
  Divider,
  Chip
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Sale from "./components/sale";
import MyCustomInput from "./components/myCustomInput";
import SquareFootIcon from "@material-ui/icons/SquareFoot";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";
import FiberManualRecordTwoToneIcon from "@material-ui/icons/FiberManualRecordTwoTone";

interface AppState {
  square: string;
  price?: number;
  lamps: string;
  spots: string;
  sale: boolean;
}

class App extends React.Component<any, AppState> {
  constructor(props: any) {
    super(props);

    this.state = {
      square: "16",
      lamps: "1",
      spots: "",
      sale: false
    };
  }

  actualSquare = (event: any, square: any) => {
    const update: any = {
      square
    };

    if (square > 20) {
      update.sale = true;
    } else {
      update.sale = false;
    }

    this.setState(update);
  };

  howManySqures = (event: React.ChangeEvent<HTMLInputElement>) => {
    // get square as number
    const square = event.target.value;
    this.setState({ square });
  };

  howManyLamps = (event: React.ChangeEvent<HTMLInputElement>) => {
    // get lamps as number
    const lamps = event.target.value;
    this.setState({ lamps });
  };

  howManySpots = (event: React.ChangeEvent<HTMLInputElement>) => {
    // get spots as number
    const spots = event.target.value;
    this.setState({ spots });
  };

  getPrice() {
    const total: number =
      +this.state.square * 449 +
      +this.state.lamps * 99 +
      +this.state.spots * 299;
    let totalString: string = total.toString();
    if (totalString.length > 3) {
      const arr = totalString.split("");
      arr.splice(-3, 0, " ");
      totalString = arr.join("");
    }
    const postfix = "р.";
    return `${totalString} ${postfix}`;
  }

  render() {
    return (
      <div className="container">
        <CssBaseline />
        <Card>
          <CardContent>
            {!this.state.sale ? (
              <Typography variant="h6">Расчет стоимости потолков</Typography>
            ) : null}
            <Sale sale={this.state.sale} />
            <div style={{marginTop: '10px'}}>
              {this.state.square ? (
                <Chip
                  icon={<SquareFootIcon />}
                  label={`Площадь: ${this.state.square} м2`}
                  color="primary"
                />
              ) : null}
              {this.state.lamps ? (
                <Chip
                  icon={<WbIncandescentIcon />}
                  label={`Люстры: ${this.state.lamps}`}
                  color="primary"
                />
              ) : null}
              {this.state.spots ? (
                <Chip
                  icon={<FiberManualRecordTwoToneIcon />}
                  label={`Светильники: ${this.state.spots}`}
                  color="primary"
                />
              ) : null}
            </div>
            {/* <Typography variant="subtitle2">
              Площадь: {this.state.square} м<sup>2</sup>, Люстры:{" "}
              {this.state.lamps}, Светильники: {this.state.spots}
            </Typography> */}
            <Typography
              variant="h5"
              style={{ marginBottom: "45px", marginTop: "15px" }}
            >
              Стоимость от: {this.getPrice()}
            </Typography>
            <Slider
              min={3}
              max={100}
              value={+this.state.square}
              valueLabelDisplay="on"
              onChange={this.actualSquare}
            />
            <MyCustomInput
              endAdornment="м2"
              onChange={this.howManySqures}
              value={this.state.square}
              inputLabel="Площадь потолков"
            />
            <MyCustomInput
              endAdornment="шт."
              onChange={this.howManyLamps}
              value={this.state.lamps}
              inputLabel="Люстры"
            />
            <MyCustomInput
              endAdornment="шт."
              onChange={this.howManySpots}
              value={this.state.spots}
              inputLabel="Монтаж светильников"
            />
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default App;
