import React from "react";
import {
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment
} from "@material-ui/core";

interface MyCustomInputProps {
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  value: string;
  inputLabel: string;
  endAdornment: string;
}

class MyCustomInput extends React.Component<MyCustomInputProps> {
  render() {
    return (
      <FormControl fullWidth variant="filled" style={{ marginBottom: "20px" }}>
        <InputLabel>{this.props.inputLabel}</InputLabel>
        <FilledInput
          type="number"
          onChange={this.props.onChange}
          value={this.props.value}
          endAdornment={<InputAdornment position="end">{this.props.endAdornment}</InputAdornment>}
        />
      </FormControl>
    );
  }
}

export default MyCustomInput;