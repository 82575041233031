import React from "react";
import { Zoom, Chip, Avatar, Typography } from "@material-ui/core";
import { Face } from "@material-ui/icons";

function Sale(props: any) {
  return props.sale ? (
    <Zoom
      in={props.sale}
      style={{
        transitionDelay: props.sale ? "500ms" : "0ms",
        margin: "0 auto"
      }}
    >
      <Chip
        // icon={<Face />}
        avatar={<Avatar alt="George" src="/images/george.jpg" />}
        label="Скидки до 55% на доп. работы!"
        color="secondary"
      />
    </Zoom>
  ) : null;
}

export default Sale;
